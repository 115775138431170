/**
 * @fileoverview
 * В этот файл добавляем полифиллы, которые нужны ТОЛЬКО поддерживающим ES2015+ браузерам.
 * Полифиллы для старых версий браузеров добавляем сюда: {@link res/js/polyfills.js}
 */

/**
 * Object.values
 * https://stackoverflow.com/questions/7306669/how-to-get-all-properties-values-of-a-javascript-object-without-knowing-the-key/16643074#16643074
 */
if (!Object.values) {
    Object.defineProperty(Object, 'values', {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (target) {
            'use strict';
            return Object.keys(target).map(function (key) {
                return target[key];
            });
        }
    });
}

/**
 * NodeList: forEach()
 * https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
 */
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/**
 * AbortController
 */
(function (self) {
    var isNeeded = (typeof (self.Request) === 'function' && !self.Request.prototype.hasOwnProperty('signal')) ||
        !self.AbortController;

    if (!isNeeded) {
        return;
    }

    function AbortSignal() {
        // Compared to assignment, Object.defineProperty makes properties non-enumerable by default and
        // we want Object.keys(new AbortController().signal) to be [] for compat with the native impl

        Object.defineProperty(this, 'listeners', {value: {}, writable: true, configurable: true});
        Object.defineProperty(this, 'aborted', {value: false, writable: true, configurable: true});
        Object.defineProperty(this, 'onabort', {value: null, writable: true, configurable: true});
    }

    AbortSignal.prototype = {

        addEventListener: function (type, callback) {
            if (!(type in this.listeners)) {
                this.listeners[type] = [];
            }
            this.listeners[type].push(callback);
        },

        dispatchEvent: function (event) {
            if (event.type === 'abort') {
                this.aborted = true;
                if (typeof this.onabort === 'function') {
                    this.onabort.call(this, event);
                }
            }

            if (!(event.type in this.listeners)) {
                return;
            }

            var stack = this.listeners[event.type];
            for (var i = 0, l = stack.length; i < l; i++) {
                stack[i](event);
            }
            return !event.defaultPrevented;
        },

        removeEventListener: function (type, callback) {
            if (!(type in this.listeners)) {
                return;
            }
            var stack = this.listeners[type];
            for (var i = 0, l = stack.length; i < l; i++) {
                if (stack[i] === callback) {
                    stack.splice(i, 1);
                    return;
                }
            }
        },

        toString: function () {
            return '[object AbortSignal]';
        }
    }


    function AbortController() {
        // Compared to assignment, Object.defineProperty makes properties non-enumerable by default and
        // we want Object.keys(new AbortController()) to be [] for compat with the native impl
        Object.defineProperty(this, 'signal', {value: new AbortSignal(), writable: true, configurable: true});
    }

    AbortController.prototype = {
        abort: function () {
            var event;
            try {
                event = new Event('abort');
            } catch (e) {
                // For Internet Explorer 11:
                event = document.createEvent('Event');
                event.initEvent('abort', false, false);
            }

            this.signal.dispatchEvent(event);
        },
        toString: function () {
            return '[object AbortController]';
        }
    }

    if (typeof Symbol !== 'undefined' && Symbol.toStringTag) {
        // These are necessary to make sure that we get correct output for:
        // Object.prototype.toString.call(new AbortController())
        AbortController.prototype[Symbol.toStringTag] = 'AbortController';
        AbortSignal.prototype[Symbol.toStringTag] = 'AbortSignal';
    }

    self.AbortController = AbortController;
    self.AbortSignal = AbortSignal;

})(window);

/**
 * Object.entries
 */
if (!Object.entries) {
    Object.defineProperty(Object, 'entries', {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (target) {
            'use strict';
            return Object.keys(target).map(function (key) {
                return [key, target[key]];
            });
        }
    });
}

/* getBoundingClientRect for window for consistency with Element.getBoundingClientRect in some res/js/app modules */
if (!window.getBoundingClientRect) {
    window.getBoundingClientRect = function () {
        return {
            top: 0,
            left: 0,
            bottom: window.innerHeight || window.clientHeight,
            right: window.innerWidth || window.clientWidth
        };
    };
}

if (!Promise.allSettled) {
    Promise.allSettled = function () {
        var args = Array.prototype.slice.call(arguments.length === 1 && Array.isArray(arguments[0]) ? arguments[0] : arguments);

        return Promise.all(args.map(function (promise) {
            return promise
                .then(function (value) {
                    return {
                        status: 'fulfilled',
                        value: value
                    }
                })
                .catch(function (reason) {
                    return {
                        status: 'rejected',
                        reason: reason
                    }
                })
        }))
    }
}

if (!Promise.any) {
    function reverse(promise) {
        return new Promise(function (resolve, reject) {
            return Promise.resolve(promise).then(reject, resolve);
        })
    }

    Promise.any = function () {
        var args = Array.prototype.slice.call(arguments.length === 1 && Array.isArray(arguments[0]) ? arguments[0] : arguments);

        return reverse(Promise.all(args.map(reverse)));
    }
}

if (!Promise.prototype.finally) {
    Promise.prototype.finally = function (fn) {
        function onFinally(callback) {
            return Promise.resolve(fn()).then(callback);
        }

        return this.then(
            function (result) {
                return onFinally(function () {
                    return result
                })
            },
            function (reason) {
                return onFinally(function () {
                    return Promise.reject(reason)
                })
            }
        )
    }
}
